import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderBottom from "../components/HeaderBottom";

const CustomerPageTemplate = ({ data, pageContext, location }) => {
  let customer = data.customer.frontmatter;
  console.log("customer: ", customer);
  const siteTitle = data.site.siteMetadata.title;
  const siteMetadata = data.site.siteMetadata;
  // const { previous, next } = pageContext;
  // console.log("Contact page data", page, location);

  customer.name = customer.name ? customer.name : "N/A";
  customer.nickname = customer.nickname ? customer.nickname : customer.name;
  customer.birthDate = customer.birthDate ? customer.birthDate : "N/A";
  customer.memberDate = customer.memberDate ? customer.memberDate : "N/A";
  customer.city = customer.city ? customer.city : "N/A";
  customer.favouriteGame = customer.favouriteGame
    ? customer.favouriteGame
    : "N/A";
  customer.occupation = customer.occupation ? customer.occupation : "N/A";

  customer.mouseName = customer.mouseName ? customer.mouseName : "N/A";
  customer.mouseBrand = customer.mouseBrand ? customer.mouseBrand : "N/A";
  customer.keyboardName = customer.keyboardName ? customer.keyboardName : "N/A";
  customer.keyboardBrand = customer.keyboardBrand
    ? customer.keyboardBrand
    : "N/A";
  customer.gamingChairName = customer.gamingChairName
    ? customer.gamingChairName
    : "N/A";
  customer.gamingChairBrand = customer.gamingChairBrand
    ? customer.gamingChairBrand
    : "N/A";
  customer.gamingTableName = customer.gamingTableName
    ? customer.gamingTableName
    : "N/A";
  customer.gamingTableBrand = customer.gamingTableBrand
    ? customer.gamingTableBrand
    : "N/A";
  customer.monitorName = customer.monitorName ? customer.monitorName : "N/A";
  customer.monitorBrand = customer.monitorBrand ? customer.monitorBrand : "N/A";
  customer.headphoneName = customer.headphoneName
    ? customer.headphoneName
    : "N/A";
  customer.headphoneBrand = customer.headphoneBrand
    ? customer.headphoneBrand
    : "N/A";

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class:
            "scroll-is--active bg-image bg-fixed bg--texture-01 bg--dotted-3x3",
        }}
      />
      <HeaderBottom />
      <main class="site-content player-info-page" id="wrapper">
        <div class="container container--large">
          <div class="team-carousel">
            <div class="team-carousel__content">
              <div class="team-carousel__item" data-icon="lineups">
                <div class="row">
                  <div class="col-lg-6">
                    <h3 class="player-info-subtitle h4 text-uppercase">
                      {customer.name}
                    </h3>
                    <h2 class="player-info-title h1">{customer.nickname}</h2>
                    <div class="row">
                      <div class="col-6 col-md-6 col-xl-4">
                        <div class="player-info-detail">
                          <div class="player-info-detail__label">Birthdate</div>
                          <div class="player-info-detail__title">
                            {customer.birthdate}
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6 col-xl-4">
                        <div class="player-info-detail">
                          <div class="player-info-detail__label">
                            Member Since
                          </div>
                          <div class="player-info-detail__title">
                            {customer.memberDate}
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6 col-xl-4">
                        <div class="player-info-detail">
                          <div class="player-info-detail__label">Born</div>
                          <div class="player-info-detail__title">{`${customer.city}, INDONESIA`}</div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6 col-xl-4">
                        <div class="player-info-detail">
                          <div class="player-info-detail__label">
                            Favorite Game
                          </div>
                          <div class="player-info-detail__title">
                            {customer.favouriteGame}
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6 col-xl-4">
                        <div class="player-info-detail">
                          <div class="player-info-detail__label">
                            Occupation
                          </div>
                          <div class="player-info-detail__title">
                            {customer.occupation}
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6 col-xl-4">
                        <div class="player-info-detail">
                          <div class="player-info-detail__label">Social</div>
                          <ul class="social-menu social-menu--default">
                            {customer.whatsappUrl && (
                              <li>
                                <a
                                  href={customer.whatsappUrl}
                                  target="_blank"
                                ></a>
                              </li>
                            )}
                            {customer.instagramUrl && (
                              <li>
                                <a
                                  href={customer.instagramUrl}
                                  target="_blank"
                                ></a>
                              </li>
                            )}
                            {customer.youtubeUrl && (
                              <li>
                                <a
                                  href={customer.youtubeUrl}
                                  target="_blank"
                                ></a>
                              </li>
                            )}
                            {customer.twitchUrl && (
                              <li>
                                <a
                                  href={customer.twitchUrl}
                                  target="_blank"
                                ></a>
                              </li>
                            )}
                            {customer.tiktokUrl && (
                              <li>
                                <a
                                  href={customer.tiktokUrl}
                                  target="_blank"
                                ></a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {customer.shortIntro && <p>{customer.shortIntro}</p>}
                  </div>
                </div>
              </div>
              <div class="team-carousel__item" data-icon="achievements">
                <div class="row">
                  <div class="col-lg-6">
                    <h3 class="player-info-subtitle h5">{customer.nickname}</h3>
                    <h2 class="player-info-title text-uppercase">
                      Certification
                    </h2>
                    <div class="row">
                      <div class="col-sm-12 col-md-8 col-xl-8">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon player-info-detail__icon--lg">
                            <svg
                              role="img"
                              class="df-icon df-icon--award-medal"
                            >
                              <use href="/assets/img/necromancers.svg#award-medal" />
                            </svg>
                          </div>
                          <div class="player-info-detail__body">
                            <div class="player-info-detail__title">
                              Elite Member Badge
                            </div>
                            <div class="player-info-detail__label color-primary">{`Apex Warrior declares ${customer.name} a.k.a. ${customer.nickname} is a loyal and certified customer.`}</div>
                            <div class="player-info-detail__label">{`since  ${customer.memberDate}`}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row row-between-xl-2col">
                      <div class="col-md-4 col-xl-4">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon">
                            <svg role="img" class="df-icon df-icon--damage">
                              <use href="/assets/img/necromancers.svg#damage" />
                            </svg>
                          </div>
                          {customer.purchases && (
                            <div class="player-info-detail__body">
                              <div class="player-info-detail__title">
                                {customer.purchases &&
                                  customer.purchases.length}
                              </div>
                              <div class="player-info-detail__label">
                                Confirmed Transactions
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {customer.purchases &&
                    <div class="row">
                      <div class="col">
                        <table class="table table-striped table-dark customer-purchase-table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Item</th>
                              <th scope="col">Purchase Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customer.purchases.map((order, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{order.itemName}</td>
                                  <td>{order.purchaseDate}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div class="team-carousel__item" data-icon="hardware">
                <div class="row">
                  <div class="col-lg-6">
                    <h3 class="player-info-subtitle h5">{customer.nickname}</h3>
                    <h2 class="player-info-title text-uppercase">War Gear</h2>
                    <div class="row">
                      <div class="col-6 col-md-6">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon player-info-detail__icon--lg">
                            <svg
                              role="img"
                              class="df-icon df-icon--gamer-mouse"
                            >
                              <use href="/assets/img/necromancers.svg#gamer-mouse" />
                            </svg>
                          </div>
                          <div class="player-info-detail__body">
                            <div class="player-info-detail__title">
                              {customer.mouseName}
                            </div>
                            <div class="player-info-detail__label color-primary">
                              {customer.mouseBrand}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon player-info-detail__icon--lg">
                            <svg role="img" class="df-icon df-icon--headset">
                              <use href="/assets/img/necromancers.svg#headset" />
                            </svg>
                          </div>
                          <div class="player-info-detail__body">
                            <div class="player-info-detail__title">
                              {customer.headphoneName}
                            </div>
                            <div class="player-info-detail__label color-primary">
                              {customer.headphoneBrand}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon player-info-detail__icon--lg">
                            <svg role="img" class="df-icon df-icon--keyboard">
                              <use href="/assets/img/necromancers.svg#keyboard" />
                            </svg>
                          </div>
                          <div class="player-info-detail__body">
                            <div class="player-info-detail__title">
                              {customer.keyboardName}
                            </div>
                            <div class="player-info-detail__label color-primary">
                              {customer.keyboardBrand}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon player-info-detail__icon--lg">
                            <svg role="img" class="df-icon df-icon--screen">
                              <use href="/assets/img/necromancers.svg#screen" />
                            </svg>
                          </div>
                          <div class="player-info-detail__body">
                            <div class="player-info-detail__title">
                              {customer.monitorName}
                            </div>
                            <div class="player-info-detail__label color-primary">
                              {customer.monitorBrand}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon player-info-detail__icon--lg">
                            <svg
                              role="img"
                              class="df-icon df-icon--gaming-chair"
                            >
                              <use href="/assets/img/necromancers.svg#gaming-chair" />
                            </svg>
                          </div>
                          <div class="player-info-detail__body">
                            <div class="player-info-detail__title">
                              {customer.gamingChairName}
                            </div>
                            <div class="player-info-detail__label color-primary">
                              {customer.gamingChairBrand}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-6">
                        <div class="player-info-detail player-info-detail--icon">
                          <div class="player-info-detail__icon player-info-detail__icon--lg">
                            <svg
                              role="img"
                              class="df-icon df-icon--mousepad-and-mouse"
                            >
                              <use href="/assets/img/necromancers.svg#mousepad-and-mouse" />
                            </svg>
                          </div>
                          <div class="player-info-detail__body">
                            <div class="player-info-detail__title">
                              {customer.gamingTableName}
                            </div>
                            <div class="player-info-detail__label color-primary">
                              {customer.gamingTableBrand}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {customer.youtubeVideoID && (
                <div class="team-carousel__item" data-icon="replay">
                  <div class="row">
                    <div class="col-lg-6">
                      <h3 class="player-info-subtitle h5">
                        {customer.nickname}
                      </h3>
                      {/* <a href="" class="btn btn-twitch float-right"><i class="fab fa-twitch">&nbsp;</i>Follow Me!</a> */}
                      <h2 class="player-info-title text-uppercase">Video</h2>

                      <article
                        class="stream has-post-thumbnail"
                        data-id={customer.youtubeVideoID}
                        data-controls="true"
                        data-provider="youtube"
                        data-thumbnail="/assets/img/samples/stream-img-01.jpg"
                        data-setsize="true"
                        data-easy-embed
                      >
                        <div class="stream__thumbnail">
                          <img
                            src="/assets/img/samples/stream-img-01.jpg"
                            alt=""
                          />
                        </div>
                        <div class="stream__icon"></div>
                        {/* <div class="stream__header">
                  <div class="stream__info">
                    <div class="stream__avatar"><img src="/assets/img/samples/streams-archive-avatar-01.jpg" alt="" /></div>
                    <h6 class="stream__title">Davikinger plays &quot;Clash of eternity&quot; <span class="badge badge-danger badge-live">Live</span></h6>
                    <div class="stream__date">August 3rd, 2018</div>
                  </div>
                </div> */}
                      </article>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class="team-player">
            {customer.portrait && (
              <div class="team-player__photo">
                {/* <img src="/assets/img/samples/player-info-page-player-photo-05.png" alt="" /> */}
                <img src={customer.portrait} alt="" />
              </div>
            )}
            <div class="team-player__base">
              <div class="ncr-page-decor ncr-page-decor--color-5">
                <div class="ncr-page-decor__layer-1">
                  <div class="ncr-page-decor__layer-bg"></div>
                </div>
                <div class="ncr-page-decor__layer-2"></div>
                <div class="ncr-page-decor__layer-3">
                  <div class="ncr-page-decor__layer-bg"></div>
                </div>
                <div class="ncr-page-decor__layer-4"></div>
                <div class="ncr-page-decor__layer-5"></div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default CustomerPageTemplate;

export const pageQuery = graphql`
  query CustomerBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        instaStoreUrl
        facebookUrl
        whatsappUrl
        tokopediaUrl
        shopeeUrl
      }
    }
    home: markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        waNum
        waMessage
      }
    }
    customer: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date
        decodedID
        memberDate(locale: "id", formatString: "DD MMM YYYY")
        name
        nickname
        birthdate(locale: "id", formatString: "DD MMM YYYY")
        city
        favouriteGame
        occupation
        shortIntro
        portrait
        purchases {
          itemName
          purchaseDate(locale: "id", formatString: "DD MMM YYYY")
        }

        mouseName
        mouseBrand
        keyboardName
        keyboardBrand
        gamingChairName
        gamingChairBrand
        gamingTableName
        gamingTableBrand
        monitorName
        monitorBrand
        headphoneName
        headphoneBrand

        youtubeVideoID

        instagramUrl
        twitchUrl
        whatsappUrl
        tiktokUrl
        youtubeUrl
      }
    }
  }
`;
